<template>
  <div class="task-submit-list">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="startTime" label="开始时间" width="100" />
      <el-table-column prop="endTime" label="结束时间" width="100" />
      <el-table-column prop="content" label="内容描述" show-overflow-tooltip />
      <el-table-column prop="fileList" label="附件" width="300">
        <template #default="scope">
          <ml-list
            fileName="fileName"
            :textMaxWidth="150"
            showDown
            :showDelete="false"
            :list="scope.row.fileList"
          />
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间" width="100">
        <template #default="scope">
          <span>{{ formetData(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createUser" label="操作" width="80">
        <template #default="scope">
          <el-button type="text" @click="handleDelete(scope.row)" style="color: #f56c6c"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <!-- 删除设备提示框 -->
    <ml-dialog ref="deleteDialogRef" :content="deleteData.content" @click-submit="submitDelete" />
  </div>
</template>
<script>
import { useStore } from 'vuex'
import { reactive, ref } from 'vue'
import { formetData } from '@/utils'
export default {
  name: 'taskSubmitList',
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  setup(props, context) {
    const { dispatch, commit } = useStore()
    const deleteDialogRef = ref(null)
    const deleteData = reactive({
      content: '确定删除该提交记录吗？',
      id: ''
    })
    const handleDelete = row => {
      deleteDialogRef.value.showDialog = true
      deleteData.id = row.id
    }
    const submitDelete = () => {
      dispatch('fetchDeleteTaskSubmitRecord', { id: deleteData.id }).then(res => {
        if (res.code === 200) {
          deleteDialogRef.value.showDialog = false
          context.emit('success')
          commit('setError', {
            status: true,
            title: '',
            message: '删除成功',
            type: 'success'
          })
        }
      })
    }
    return {
      handleDelete,
      submitDelete,
      deleteData,
      formetData,
      deleteDialogRef
    }
  }
}
</script>
<style lang="scss" scoped>
.task-submit-list {
  width: 100%;
}
</style>

