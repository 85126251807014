<template>
  <ml-dialog
    width="960px"
    ref="dialogRef"
    title="提交报告"
    :btnLoading="btnLoading"
    :showSubmitBtn="!formDisable"
    :showSecondaryBtn="!formDisable"
    secondaryText="保存草稿"
    submitText="提交"
    @click-submit="openConfirmDialog"
    @click-secondary="submitReportDialogSubmit('save')"
    @click-close="closeHandle"
  >
    <template #body>
      <div class="dialog-body">
        <div class="add-task-btn">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="addTask"
            >增加记录</el-button
          >
        </div>
        <task-submit-list
          :tableData="taskList"
          style="margin-bottom: 32px"
          @success="getTaskSubmitList"
          v-if="taskList.length > 0"
        />
        <el-collapse v-model="activeName" v-if="addList.length > 0">
          <el-collapse-item
            v-for="(item, index) in addList"
            :key="index"
            :name="index"
            :title="`报告${index + 1}`"
          >
            <template #title>
              <div class="collapse-title">
                <span class="collapse-title-text">报告{{ index + 1 }}</span>
                <el-button
                  type="text"
                  size="mini"
                  class="delete-btn"
                  icon="el-icon-delete"
                  @click.stop="deleteTask(index)"
                  >删除</el-button
                >
              </div>
            </template>
            <submit-task-form
              :ref="el => (submitTaskFormRefs[`submitTaskFormRef${index}`] = el)"
              :conf="conf"
              :btnCode="btnCode"
              :companyId="companyId"
              :sourData="sourData"
              :type="type"
              :inspectTaskId="inspectTaskId"
            />
          </el-collapse-item>
        </el-collapse>
      </div>
      <ml-dialog
        ref="confirmDialogRef"
        :content="confirmData.content"
        @click-submit="confrimHandle"
      />
    </template>
  </ml-dialog>
</template>

<script>
import { useStore } from 'vuex'
import { computed, reactive, ref, onMounted } from 'vue'
import { BASEURL, FILEUPLOADURL } from '@API'
import submitTaskForm from './submitTaskForm.vue'
import taskSubmitList from './taskSubmitList.vue'
export default {
  name: 'submitTaskDialog',
  components: {
    submitTaskForm,
    taskSubmitList
  },
  props: {
    conf: {
      type: Object,
      default() {
        return {}
      }
    },
    btnCode: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    sourData: {
      type: Object,
      default() {
        return {}
      }
    },
    type: {
      type: String,
      default: 'edit'
    },
    inspectTaskId: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const { commit, dispatch, getters } = useStore()
    const dialogRef = ref(null)
    const formDisable = ref(false)
    const btnLoading = ref(false)
    const taskList = ref([])
    const activeName = ref([0])

    const getTaskSubmitList = async () => {
      const data = await getTaskSubmitHandle()
      taskList.value = data
      if (data.length === 0 && addList.value.length === 0) {
        addTask()
      }
    }
    const getTaskSubmitHandle = async () => {
      const { data } = await dispatch('fetchSelectByTaskIdRequest', {
        inspectTaskId: props.inspectTaskId
      })
      return data
    }
    const confirmDialogRef = ref(null)
    const confirmData = reactive({
      content: '提交报告后，无法修改，确定提交吗？'
    })
    const confrimHandle = () => {
      confirmDialogRef.value.showDialog = false
      submitReportDialogSubmit('submit')
    }
    const openConfirmDialog = () => {
      confirmDialogRef.value.showDialog = true
    }
    const submitTaskFormRefs = ref([])
    const submitReportDialogSubmit = async type => {
      if (type === 'submit') {
        // 提交
        if (addList.value.length === 0 && taskList.value.length === 0) {
          commit('setError', {
            status: true,
            title: '',
            message: '请至少添加一份报告',
            type: 'error'
          })
          return
        }
      } else if (type === 'save') {
        if (addList.value.length === 0) {
          commit('setError', {
            status: true,
            title: '',
            message: '请至少添加一份报告',
            type: 'error'
          })
          return
        }
      }
      if (addList.value.length > 0) {
        try {
          // 使用 Promise.all 等待所有表单验证和提交
          btnLoading.value = true
          const promiseSign = addList.value.map((item, index) => {
            const formRef = submitTaskFormRefs.value[`submitTaskFormRef${index}`]
            if (!formRef) {
              throw new Error(`Form reference at index ${index} not found`)
            }
            return formRef.submitReportDialogSubmit()
          })

          const results = await Promise.all(promiseSign)

          let params = {
            inspectTaskId: props.inspectTaskId,
            list: results.map(item => {
              return {
                inspectTaskId: props.inspectTaskId,
                startTime: item.startTime,
                endTime: item.endTime,
                content: item.comment,
                fileList: item.fileList
              }
            }),
            signName: ''
          }
          dispatch('fetchDraftTaskRequest', params).then(res => {
            btnLoading.value = false
            if (res.code === 200) {
              if (type === 'save') {
                addList.value = []
                getTaskSubmitList()
              } else {
                submitHandle()
              }
            }
          })
        } catch (error) {
          btnLoading.value = false
          console.error('Submit error:', error)
          throw error
        }
      } else {
        submitHandle()
      }
    }

    const submitHandle = async () => {
      const taskData = await getTaskSubmitHandle()
      let params = {
        inspectTaskId: props.inspectTaskId,
        list: taskData,
        signName: ''
      }
      const dialogData = reactive({
        buttonCode: props.btnCode,
        procInsId: props.conf && props.conf.taskDto && props.conf.taskDto.processInstanceId,
        taskId: props.conf && props.conf.taskDto && props.conf.taskDto.id,
        variables: props.conf && props.conf.variables,
        comment: '',
        toUserId: '',
        customParamJson: '',
        fileList: [],
        nodeId: props.conf && props.conf.id,
        businessParamJson: JSON.stringify(params),
        created: props.conf && props.conf.taskDto && props.conf.taskDto.created,
        formConf: props.conf.formConf
      })
      btnLoading.value = true
      dispatch('fetchButtonClickRequest', dialogData)
        .then(res => {
          btnLoading.value = false
          if (res && res.code === 200) {
            commit('setError', {
              status: true,
              title: res.message,
              message: '处理成功...',
              type: 'success'
            })
            context.emit('success')
          }
        })
        .catch(() => {
          btnLoading.value = false
        })
    }

    const closeHandle = () => {
      console.log('closeHandle')
    }
    const addList = ref([])
    const addTask = () => {
      addList.value.push({
        startTime: '',
        endTime: '',
        content: '',
        fileList: []
      })
    }
    const deleteTask = index => {
      addList.value.splice(index, 1)
    }
    onMounted(() => {
      getTaskSubmitList()
    })
    return {
      dialogRef,
      formDisable,
      btnLoading,
      closeHandle,
      getTaskSubmitList,
      taskList,
      addTask,
      addList,
      activeName,
      deleteTask,
      submitTaskFormRefs,
      submitReportDialogSubmit,
      confirmDialogRef,
      confirmData,
      openConfirmDialog,
      confrimHandle
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-body {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;
}
.add-task-btn {
  margin-bottom: 10px;
}
.collapse-title {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 12px;
}
/deep/ .el-collapse-item__header {
  background: #f7f7f7;
  padding: 0 16px;
  margin-bottom: 16px;
}
.collapse-title-text {
  font-size: 16px;
  font-weight: bold;
}
.delete-btn {
  color: #f56c6c;
}
</style>
